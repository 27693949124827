import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
import BinaryDatabases from './_binary-databases.mdx';
import CoordinatesWarning from './_coordinates-warning.mdx';
import ConversionUtility from './_conversion-utility.mdx';
import CsvDatabasesIntro from './_csv-databases-intro.mdx';
import DataPrivacyWarning from './_data-privacy-warning.mdx';
import ExampleFiles from './_example-files.mdx';
import FileFormat from './_file-format.mdx';
import IpGeolocationUsageWarning from './_ip-geolocation-usage-warning.mdx';
import ReturnedValuesAsKeys from './_returned-values-as-keys.mdx';
import APIs from './_apis.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ZipFileContent = makeShortcode("ZipFileContent");
const CsvBlockTable = makeShortcode("CsvBlockTable");
const CsvLocationTable = makeShortcode("CsvLocationTable");
const CsvFileExamples = makeShortcode("CsvFileExamples");
const MmdbFileExamples = makeShortcode("MmdbFileExamples");
const DatabaseSizes = makeShortcode("DatabaseSizes");
const DatabaseChanges = makeShortcode("DatabaseChanges");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`Determine geolocation data such as country, region, state, city, ZIP/postal
code, and additional intelligence such as confidence factors, ISP, domain, and
connection type.`}</p>
    <p>{`To learn more about the GeoIP2 Enterprise database, please visit the
`}
      <a {...{
        "href": "https://www.maxmind.com/en/solutions/geoip2-enterprise-product-suite/enterprise-database",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`GeoIP2 Enterprise Database page`}</a>
      {`.`}</p>
    <p>{`If you are interested in purchasing the database, please
`}
      <a {...{
        "href": "https://www.maxmind.com/en/solutions/geoip2-enterprise-product-suite#signUp",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`contact our Enterprise Business team`}</a>
      {`
for assistance.`}</p>
    <IpGeolocationUsageWarning mdxType="IpGeolocationUsageWarning" />
    <DataPrivacyWarning mdxType="DataPrivacyWarning" />
    <div {...{
      "id": "toc-binary-database"
    }}><h2 {...{
        "id": "binary-database",
        "parentName": "div"
      }}>{`Binary Database`}</h2>
      <BinaryDatabases mdxType="BinaryDatabases" />
      <table {...{
        "parentName": "div"
      }}>

        <thead {...{
          "parentName": "table"
        }}>

          <tr {...{
            "parentName": "thead"
          }}>

            <th {...{
              "parentName": "tr"
            }}>{`Language or Framework`}</th>


            <th {...{
              "parentName": "tr"
            }}>{`Documentation`}</th>


            <th {...{
              "parentName": "tr"
            }}>{`Example`}</th>

          </tr>

        </thead>


        <tbody {...{
          "parentName": "table"
        }}>

          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`.NET (C#)`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-dotnet#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-dotnet#enterprise-database",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`Enterprise Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Java`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-java#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-java#enterprise",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`Enterprise Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Node.js`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-node#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-node#enterprise-example",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`Enterprise Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`PHP`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-php#database-reader",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Reader`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-php#enterprise-example",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`Enterprise Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Python`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-python#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-python#enterprise-database",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`Enterprise Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Ruby`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-ruby#database-reader",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Reader`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-ruby#enterprise-example",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`Enterprise Example`}</a></td>

          </tr>

        </tbody>

      </table></div>
    <div {...{
      "id": "toc-csv-database"
    }}><h2 {...{
        "id": "csv-database",
        "parentName": "div"
      }}>{`CSV Database`}</h2>
      <CsvDatabasesIntro mdxType="CsvDatabasesIntro" />
      <ZipFileContent productName="GeoIP2-Enterprise" isTableExcluded={true} mdxType="ZipFileContent" />
      <p {...{
        "parentName": "div"
      }}>{`The files in thie zip archive are:`}</p>
      <table>
        <tbody>
          <tr>
            <th>
              <p>{`Filename`}</p>
            </th>
            <th>
              <p>{`Description`}</p>
            </th>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`COPYRIGHT.txt`}</inlineCode></p>
            </td>
            <td>
              <p>{`Copyright statement`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`LICENSE.txt`}</inlineCode></p>
            </td>
            <td>
              <p>{`End user license`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`GeoIP2-Enterprise-Blocks-IPv4.CSV`}</inlineCode></p>
            </td>
            <td>
              <p>{`CSV file containing data on IPv4 addresses`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`GeoIP2-Enterprise-Blocks-IPv6.CSV`}</inlineCode></p>
            </td>
            <td>
              <p>{`CSV file containing data on IPv6 addresses`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`GeoIP2-Enterprise-ISP.CSV`}</inlineCode></p>
            </td>
            <td>
              <p>{`CSV file containing data on ISPs. This may be cross referenced with the various blocks files using the shared `}
                <inlineCode {...{
                  "parentName": "p"
                }}>{`isp_id`}</inlineCode>
                {` key.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`GeoIP2-Enterprise-Locations-XX.CSV`}</inlineCode></p>
            </td>
            <td>
              <p>{`CSV files containing data on locations. This may be cross referenced with the various blocks files using the shared `}
                <inlineCode {...{
                  "parentName": "p"
                }}>{`geoname_id`}</inlineCode>
                {` key.`}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <FileFormat mdxType="FileFormat" />
      <div {...{
        "id": "toc-blocks-files",
        "parentName": "div"
      }}><h3 {...{
          "id": "blocks-files",
          "parentName": "div"
        }}>{`Blocks Files`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`There are two CSV files for network blocks, one each for IPv4 and IPv6 blocks.
These files are named `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-Enterprise-Blocks-IPv4.csv`}</inlineCode>
          {` and
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-Enterprise-Blocks-IPv6.csv`}</inlineCode>
          {` respectively.`}</p>
        <CsvBlockTable isEnterprise={true} mdxType="CsvBlockTable" />
        <CoordinatesWarning mdxType="CoordinatesWarning" /></div>
      <div {...{
        "id": "toc-isp-file",
        "parentName": "div"
      }}><h3 {...{
          "id": "isp-file",
          "parentName": "div"
        }}>{`ISP File`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`A single CSV file, called `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-Enterprise-ISP.csv`}</inlineCode>
          {`, contains information on
ISPs.`}</p>
        <table>
          <thead>
            <tr>
              <th>
                <p>{`Name`}</p>
              </th>
              <th>
                <p>{`Type`}</p>
              </th>
              <th>
                <p>{`Description`}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>{`isp_id`}</p>
              </td>
              <td>
                <p>{`integer`}</p>
              </td>
              <td>
                <p>{`The identifier for the ISP.  This key can be cross referenced
with the `}
                  <inlineCode {...{
                    "parentName": "p"
                  }}>{`isp_id`}</inlineCode>
                  {` in the Blocks files.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`isp`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The name of the ISP associated with the IP address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`organization`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The name of the organization associated with the IP address.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`autonomous_system_number`}</p>
              </td>
              <td>
                <p>{`integer`}</p>
              </td>
              <td>
                <p>{`The ASN assigned to the ISP for routing.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`autonomous_system_organization`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The name of the autonomous system organization.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`connection_type`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The type of connection. This will be one of the following values:`}</p>
                <ul>

                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`Cable/DSL`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`Cellular`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`Corporate`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`Satellite`}</inlineCode></li>

                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`user_type`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The user type associated with the IP address. This
will be one of the following values.`}</p>
                <ul>

                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`business`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`cafe`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`cellular`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`college`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`consumer_privacy_network`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`content_delivery_network`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`government`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`hosting`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`library`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`military`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`residential`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`router`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`school`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`search_engine_spider`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`traveler`}</inlineCode></li>

                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`mobile_country_code`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The `}
                  <a {...{
                    "href": "https://en.wikipedia.org/wiki/Mobile_country_code",
                    "target": "_blank",
                    "rel": "nofollow noopener noreferrer",
                    "parentName": "p"
                  }}>{`mobile country code
(MCC)`}</a>
                  {` associated
with the IP address and ISP.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`mobile_network_code`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The `}
                  <a {...{
                    "href": "https://en.wikipedia.org/wiki/Mobile_country_code",
                    "target": "_blank",
                    "rel": "nofollow noopener noreferrer",
                    "parentName": "p"
                  }}>{`mobile network code
(MNC)`}</a>
                  {` associated
with the IP address and ISP.`}</p>
              </td>
            </tr>
          </tbody>
        </table></div>
      <div {...{
        "id": "toc-locations-files",
        "parentName": "div"
      }}><h3 {...{
          "id": "locations-files",
          "parentName": "div"
        }}>{`Locations Files`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`The zip file includes one location file for each locale code for which data is
available. There will always be an "en" file containing a record for every
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoname_id`}</inlineCode>
          {` used in the blocks files. Files corresponding to other locales will
exist and have a record for a `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoname_id`}</inlineCode>
          {` only if data is available. The other
possible locales are "de", "es", "fr", "ja", "pt-BR", "ru", and "zh-CN".`}</p>
        <p {...{
          "parentName": "div"
        }}>{`These files are named `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-Enterprise-Locations-{locale}.csv`}</inlineCode>
          {`. An example
name would be "GeoIP2-City-Locations-en.csv".`}</p>
        <CsvLocationTable isEnterprise={true} mdxType="CsvLocationTable" /></div>
      <div {...{
        "id": "toc-returned-values-as-database-map-dict-or-hash-keys",
        "parentName": "div"
      }}><h3 {...{
          "id": "returned-values-as-database-map-dict-or-hash-keys",
          "parentName": "div"
        }}>{`Returned Values as Database, Map, Dict, or Hash Keys`}</h3>
        <ReturnedValuesAsKeys mdxType="ReturnedValuesAsKeys" /></div>
      <div {...{
        "id": "toc-conversion-utility",
        "parentName": "div"
      }}><h3 {...{
          "id": "conversion-utility",
          "parentName": "div"
        }}>{`Conversion Utility`}</h3>
        <ConversionUtility mdxType="ConversionUtility" /></div></div>
    <div {...{
      "id": "toc-example-files"
    }}><h2 {...{
        "id": "example-files",
        "parentName": "div"
      }}>{`Example Files`}</h2>
      <ExampleFiles mdxType="ExampleFiles" />
      <div {...{
        "id": "toc-csv-example-files",
        "parentName": "div"
      }}><h3 {...{
          "id": "csv-example-files",
          "parentName": "div"
        }}>{`CSV Example Files`}</h3>
        <CsvFileExamples files={[{
          filename: 'GeoIP2-Enterprise-CSV_Example.zip',
          link: '/static/GeoIP2-Enterprise-CSV_Example.zip'
        }]} mdxType="CsvFileExamples" /></div>
      <div {...{
        "id": "toc-mmdb-example-files",
        "parentName": "div"
      }}><h3 {...{
          "id": "mmdb-example-files",
          "parentName": "div"
        }}>{`MMDB Example Files`}</h3>
        <MmdbFileExamples files={[{
          filename: 'GeoIP2-Enterprise-Test.mmdb',
          link: 'https://github.com/maxmind/MaxMind-DB/blob/main/test-data/GeoIP2-Enterprise-Test.mmdb'
        }]} mdxType="MmdbFileExamples" /></div></div>
    <div {...{
      "id": "toc-database-sizes"
    }}><h2 {...{
        "id": "database-sizes",
        "parentName": "div"
      }}>{`Database Sizes`}</h2>
      <DatabaseSizes dateRange="March to June 2024" databaseChanges={[{
        databaseName: 'GeoIP Enterprise',
        csvSizeRange: '975 MB - 1.06 GB',
        mmdbSizeRange: '341 MB - 350 MB',
        ipv4Range: '8,064,000 - 8,733,000',
        ipv6Range: '2,933,000 - 3,151,000'
      }]} mdxType="DatabaseSizes" /></div>
    <div {...{
      "id": "toc-database-changes"
    }}><h2 {...{
        "id": "database-changes",
        "parentName": "div"
      }}>{`Database Changes`}</h2>
      <DatabaseChanges product="Enterprise" mdxType="DatabaseChanges" /></div>
    <div {...{
      "id": "toc-apis-and-third-party-integrations"
    }}><h2 {...{
        "id": "apis-and-third-party-integrations",
        "parentName": "div"
      }}>{`APIs and Third-Party Integrations`}</h2>
      <APIs mdxType="APIs" /></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      